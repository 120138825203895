// DiscoverFlixProvider.js
import { connect } from 'react-redux';

import { MERGE_OPERATION_DATA } from '../ActionTypes.js';

import fetchFeeds from '../action/fetchFeeds.js';

import getListData from '../selector/getListData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';

import { FeedNameType } from '../resource/feedConstants.js';

const mapStateToProps = (state, { category }) => {
  const selectPath = ['home', FeedNameType.MESSAGES, category];
  const nextPage = getListData(state, selectPath, 'nextPage');
  const totalCount = getListData(state, selectPath, 'totalCount');
  return {
    // use token instead of userId to reduce list api call
    isAuthed: !!getMeData(state, 'token'),
    messageIds: getListData(state, selectPath, 'itemIds'),
    nextPage,
    totalCount,
    isFetched: getNetworkingData(state, [...selectPath, 1], 'isFetched'),
    isFetching: getNetworkingData(
      state,
      [...selectPath, nextPage],
      'isFetching'
    ),
    shouldPlayFeedId: getOperationData(state, ['home'], 'shouldPlayFeedId'),
    scrollLeft: getOperationData(
      state,
      ['home', 'flix', category],
      'scrollLeft'
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFeeds: data => dispatch(fetchFeeds(data)),
    setScrollLeft: ({ scrollLeft, category }) =>
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['home', 'flix', category],
          data: {
            scrollLeft,
          },
        },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
